import request from '../Axios'

// 列表
export const page = data => {
  return request({
    url: '/user/statistics/page',
    method: 'post',
    data
  })
}

// 导出记录
export const userExport = data => {
  return request({
    url: '/user/statistics/export',
    method: 'post',
    data,
    responseType: 'blob'
  })
}
